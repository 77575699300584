import React from 'react';
import { SEO } from '../components/seo';
import { Details } from '../components/company/details';
import { Introduction } from '../components/company/introduction';
import { NormalLayout } from '../components/layouts/normal_layout';

const Company: React.FC = () => {

  return (
      <NormalLayout type="company">
        <SEO title="Company | UVX Solutions" description="Company | UVX Solutions" />
        <Introduction />
        <Details />
      </NormalLayout>
  );
};

export default Company;